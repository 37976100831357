@font-face{
  font-family: "Raleway-Regular";
  src:url(./Fonts/Raleway-Regular.ttf);
}
@font-face{
  font-family: "Raleway-Black";
  src:url(./Fonts/Raleway-Black.ttf);
}

@font-face{
  font-family: "Raleway-Light";
  src:url(./Fonts/Raleway-Light.ttf);
}

@font-face{
  font-family: "Raleway-ExtraBold";
  src:url(./Fonts/Raleway-ExtraBold.ttf);
}


/*Spot Color Objects:
PANTONE 2945 C (Lab)#004c97	azul
PANTONE 361 C (Lab)#43B02A	verde
*/

.texto-xl{
  font-size:4rem;
}
.texto-l{
  font-size:3rem;
}
.texto-m{
  font-size:1.4rem;
}
.texto-s{
  font-size:1rem;
}
.texto-xs{
  font-size:0.5rem;
}
.texto-white{
  color: white;
}
.texto-pink{
  color: #CC483A !important;
}
.texto-blue{
  color: #004c97;
}
.texto-bolder{
  font-family: "Raleway-ExtraBold";
}
.texto-bold{
  font-family: "Raleway-Black";
}
.texto-normal{
  font-family: "Raleway-Regular";
}
.texto-light{
  font-family: "Raleway-Light";
}

body {
  margin: 0  auto;
  width: 100%;
  text-align: center;
  color:#4D4D4D; 
  font-family: "Raleway-Regular";
}


#encabezado {
  color: #666666;
  background-color: white;
	top:0;
	width: 100%;
  position: fixed;
  z-index:10000;
}

.icon{
  display:none;
}
#contenido{  
  display:flex;
  flex-direction: column;  
  background: white;
  width: 100%;
}

.Contenedor {
  display:flex;
  flex-direction: column;    
  text-decoration: none;    
  align-items: center;
  justify-content: center;
  width: 100%;
}
.nav-sup{
  width:100%;
  display:flex;
  align-items:center;
  margin:10px 0;
}

.Encabezado{
  width:100%; 
  display: flex; 
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-family: "Raleway-Light";
  height: 105px;
}

.logo-container{
  width:10%;
  display:flex;
}
.selectors{
  display:flex;
  flex-direction: row;
  position:absolute;
  bottom:5%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.selector-dot{
  color:black;
  background-color: white;
  border-color:white;
  opacity: 0.50;
  border-radius: 50%;
  height:1rem;
  width:1rem;
  padding:0.5rem;
  margin: 0 1rem;
  cursor: pointer;
  outline: none;
}
.selector-dot.dot-active{
  color:white;
  opacity: 1;;
}

#contenido{
  margin-top: 105px;
}
.menu-nav{
  display: flex; 
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-family: "Raleway-Light";
  height: 105px;
  width:80%;
}
.menu-nav > a{
  color: #666666;
  font-family: "Raleway-Light";
  padding: 1rem;
  font-size: 1rem;
}

.menu-nav > a:hover{
  color: #CC483A;
}

.menu-nav > .menu-selected{
  color:#CC483A;
  font-family: "Raleway-Regular";
  border-bottom: 1px solid #CC483A;
}

a{
  text-decoration: none;
}

.button-pink{
  min-height: 2em;
  font-size: 1.5em;
  border:2px solid #CC483A;
  color: #CC483A;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 3rem;
  border-radius:9999px;
}
.PieDePagina{    
  display: flex;
  flex-direction: column;  
  background-color:#043A63;    
  width: 100%; 
  color:white;
  text-align:left;
  padding-bottom: 1em;
  justify-content: space-between;
}
.footer-social{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 5rem;
}
.social-logo-pink{
  width: 2rem;
  height: 2rem;
  fill: #CC483A;
}
.footer-info{
  margin-top:4em;
  padding: 2em;
  display:flex;
  flex-direction:row;
  justify-content: space-between;
}
.footer-left{
  display:flex;
  flex-direction:column;
  justify-content: space-around;
  text-align:left;
  width: 30%;
}
.footer-left > a{
  color:white;
  width: 100%;
}
.footer-center{
  display:flex;
  flex-direction: column;
  width: 30%;
  justify-content: center;
  align-items: center;
  align-content: center;
  }
.footer-center > img{
  width:50%;
}
.footer-final{
  display:flex;
  flex-direction:column;
  align-items:center;
}
.footer-right{
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  width: 60%;
}
.footer-data{
  display:flex;
  flex-direction: column;;
}

.diagnostic-icon{
  width:4rem;
  height:5rem;
  margin-bottom:2rem;
  fill: #666666;
  stroke: #666666;
  cursor: pointer;
  stroke-width: 2px;
}

.diagnostic-icon .cls-1{
  stroke: #666666;
}

.diagnostic-icon.selected{
  fill: #CC483A;
  stroke: #CC483A;
}

.diagnostic-icon.selected .cls-1{
  fill: #CC483A;
  stroke: #CC483A;
}
.diagnostic-image:hover{
  border:3px solid #CC483A;
}
.diagnostic-image.selected{
  border: 3px solid #CC483A;
}
.button-pink:hover{
  background-color: #CC483A !important;
  color:white;
}
.Modal{ 
  position: fixed;
  top:0px;
  right:0px;
  left:0px;
  width:100%;
  height: 100%;
  background-color: rgba(0,0,0,0.75);
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index:10003;
  box-sizing: border-box;
}


.paciente-content{
  width: 100%;
  display:flex;
  flex-direction:row;
  justify-content: space-around;
}
.paciente-quote{
  width:15%;
}
.paciente-post{
  width: calc(25% - 6px);
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.paciente-text{
  display:flex;
  flex-direction: column;
  width:75%;
}
.paciente-image{
  width:40%;
  display:flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.data-graphic-section{
  width:400px;  
  height:500px;
  display:flex;
  flex-direction:column; 
  align-items: center; 
  justify-content:space-around; 
  font-size:1rem;
}
.data-graphic-circle{
  width:300px; 
  height:300px;
  display:flex;
  flex-direction:column; 
  align-items: center;
  justify-content:space-around; 
  font-size:1rem;
  box-sizing:border-box; 
  position:relative
}

.moreinfo{
  display:flex; 
  flex-direction:row; 
  align-items: grow;
}
.moreinfo-section{
  box-sizing: border-box;
  width: 33%; 
  display:flex;
  flex-direction:column; 
  align-items: center; 
  justify-content:space-around;
  font-size:1rem;
}
.moreinfo-image{
  width:100%; 
  margin-bottom:2rem; 
  display:flex;
  flex-direction:column;
  align-items: center; 
  justify-content:space-around; 
  font-size:'1rem';
}
.moreinfo-text{
  width:80%; 
  height:200px;   
  display:flex; 
  flex-direction:column; 
  text-align:left;
  justify-content:space-between;
}
.antesdespues-image{
  width:50%; 
  display:flex; 
  flex-direction:column; 
  margin:2rem auto;
}
.soy-candidata{
  width:50%;
  height:400px;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-around;
  font-size:1rem;
}
.resultimage{
  width:20%; 
  display:flex; 
  flex-direction:column; 
  justify-content:space-around; 
  align-items:center;
  box-sizing: border-box;
}
.modal-image{
  width:50%;
}
.diagnostico-options{
  width:50%;
}
@media(min-width:1921px){
  body{
    max-width: 1920px;
  }
  .Encabezado{
    max-width: 1920px;
  }
}
@media (max-width: 767px) {
  html{
    font-size: 12px;
  }
  body{
    font-size: 12px;
  }
  
  .texto-s{
    font-size:0.75rem;
  }
  .texto-m{
    font-size:1rem;
  }
  .texto-xl{
    font-size:2rem;
  }
  .texto-l{
    font-size:1.5rem;
  }
  .Encabezado div:not(:first-child){
    display:none;
  }
  .Encabezado > a{
    display:none;
  }
  .nav-sup button.icon{
    width: 20%;
    display:flex;
    align-items: center;
    justify-content: center;
    border: none;
    box-sizing: border-box;
    align-self: flex-start;
  }
  .nav-sup button.icon i{
    color: white;
    cursor: pointer;
    border: solid 4px #CC483A ;
    border-radius: 10%;
    margin-top:2px;
    background-color: #CC483A;
  }

  .nav-sup .Encabezado{
    width:80%;
    height:50px;
    box-sizing: border-box;
  }

  .logo-container{
    margin-top:10px;
    width:30%;
    max-height: 50px;
  }

  .nav-sup{
    justify-content: space-around;
    align-items: flex-start;
    height:auto;
  }
  #contenido{
    margin-top:50px;
  }
  .menu-nav{
    display: none;
  }
  .Encabezado.responsive {
    position: relative; 
    background-color: white;
    height: auto;
  }

  .Encabezado.responsive{
    display:flex;
    align-items: flex-start;
    background-color: white;
    flex-wrap: wrap;
    width:80%;
  }

  .Encabezado.responsive .menu-nav {
    display:flex;
    flex-direction: column;
    width:130%;
  }
  .Encabezado.responsive .menu-nav a{
    height:40px;
    font-size: 1rem;

    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
  }

  .Encabezado.responsive .menu-nav a.selected{
    background-color: transparent;
  }

  .Encabezado.responsive .menu-follow{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:130%;
    border: none;
    font-size: 1rem;
  }



  .Encabezado.responsive .menu-follow .follow-us{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    width:50px;
  }
  
  .Encabezado.responsive .menu-follow .follow-us .footer-social{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    width:100%;
  }


  .Encabezado.responsive .menu-follow .follow-us .footer-social a{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    width:20px;
  }

  .Encabezado.responsive .menu-follow .follow-us .footer-social a img{
    width:100%;
  }
  .nav-sup{
    margin:0px 0px;
  }
  
  .footer-left{
    width:33%;
    padding-right: 1rem;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .footer-left a{
    margin: 1rem 0;
  }
  .footer-center{
    display:none;
  }
  .footer-right{
    width:66%;
    padding:1rem;
    border-left: 1px solid white;
  }
  .footer-data{
    width:100%;
    padding:0em 1em;
    text-align: justify;
  }
  .Header{
    font-size:0.8rem;
    width: 100%;
  }
  .Header > a > img{
    object-fit: contain;;
  }
  .HeaderPrincipal{
    height: 200px;
    min-height: 200px;
  }
  .BannerA{  
    min-height: 250px;
  }
  
  .Cuadro{
    width: calc(50% - 10px);
  }
  .paciente-post{
    box-sizing: border-box;
    width: 100%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding:1em;
  }
  .paciente-image{
    width: 25%;
  }

  .cacahuates{
    width:100%;
    display:flex;
    flex-direction:row; 
    justify-content:space-between; 
    flex-wrap: wrap;
    padding:2rem 0;
  }
  .cacahuate{
    width: calc(50% - 50px);
    height: 380px;
    margin: 0.5em;
  }
  .Mosaico2{
    display: none;
  }
  .BannerH{
    flex-direction: column;;
  }
  .timeline-banner{
    height: 600px;
  }
  .timeline-data{
    flex-direction: column;
    font-size: 0.8rem;
  }
  .timeline-image{
    height: 60%;
    width: 100%;
  }
  .timeline-post{
    height: 40%;
    width: 100%;
  }
  .timeline-selector{
    font-size: 0.75rem;
    min-height:2rem;
    height:2rem;
  }
  .texto-mapa{
    font-size: 0.45rem;
  }
  .share_icon {
    fill: #ffffff !important;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.5rem 0.5rem;
  }  
  .data-graphic-circle{
    width:150px; 
    height:150px;
  }
  .data-graphic-section{
    width: 80%;
    height: auto;
    margin: 2rem auto;
  }
  .celulitis-data{
    flex-wrap: wrap;
  }
  .moreinfo{
    flex-direction: column;
    align-items: center;
  }
  .moreinfo-section{
    width: 100%;
    margin: 2rem auto;
  }
  .moreinfo-image{
    margin-bottom: 1rem;
  }
  .moreinfo-text{
    height: auto;
    display:flex;
    flex-direction: column;
  }
  .moreinfo-text a{
    margin-top: 2rem;
    align-self: center;
  }
  .antesdespues-image{
    width: 80%;
  }
  .soy-candidata{
    width:80%;
    height:200px;
    font-size:1rem;
  }
  .resultimage{
    margin: 1rem auto;
    width:40%;
  }
  .modal-image{
    width:90%;
  }
  .diagnostico-options{
    width:80%;
  }
}